export const ALGOLIA_APPLICATION_ID = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID;
export const ALGOLIA_APPLICATION_KEY = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_KEY;
export const ALGOLIA_INDEX_NAME = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME;
export const BEARERTOKEN_USERNAME = process.env.NEXT_PUBLIC_BEARERTOKEN_USERNAME;
export const BEARERTOKEN_PASSWORD = process.env.NEXT_PUBLIC_BEARERTOKEN_PASSWORD;
export const BEARERTOKEN_BASE_URL = process.env.NEXT_PUBLIC_BEARERTOKEN_BASE_URL;
export const BACKEND_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL;
export const COMMERCETOOLS_BASE_URL = process.env.NEXT_PUBLIC_COMMERCETOOLS_BASE_URL;
export const AZURE_CLIENT_ID = process.env.NEXT_PUBLIC_AZURE_CLIENT_ID;
export const AZURE_TENANT_ID = process.env.NEXT_PUBLIC_AZURE_TENANT_ID;
export const LOGIN_DATA_ENC_KEY = process.env.NEXT_PUBLIC_LOGIN_DATA_ENC_KEY;
export const REVIEW_ID = process.env.NEXT_PUBLIC_REVIEW_ID;
export const STATE_INITIAL = process.env.NEXT_PUBLIC_STATE_INITIAL;
export const STATE_TRANSIT = process.env.NEXT_PUBLIC_STATE_TRANSIT;
export const STATE_DELIVERED = process.env.NEXT_PUBLIC_STATE_DELIVERED;
export const STATE_CANCELLED = process.env.NEXT_PUBLIC_STATE_CANCELLED;
export const BACKEND_AMP_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_AMP_BASE_URL;
export const categoryCodes = {
    MRO: 3,
    Chemicals: 3,
    Laboratory_Chemicals: 3120,
    ReagentsSolvents: 3120,
    Laboratory_Consumables: 31,
    Glassware: 3110,
    Lab_Columns: 3130,
    TestTubeWORIM: 31101,
    GlassStirrerRod: 31103,
    GLStirrerRodWithPTFE: 31104,
    Peptides: 3120,
    Antibodies: 3120,
    Protein: 3120,
    Recombinant_Protein: 3120,
    Microbial_Culture: 3120,
    Expression_System_Kit: 3120,
    Other_Products: 3120,
}
