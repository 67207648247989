import { Account } from '@Types/account/Account';
import { Address } from '@Types/account/Address';
import Field from 'components/commercetools-ui/account/details/field';
import { REMEMBER_ME } from 'helpers/constants/localStorage';
import useSWR, { mutate } from 'swr';
//import Types from '@Types/'
import { revalidateOptions } from 'frontastic';
import { fetchApiHub, resetFetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { checkIfUserIsSSOVerified } from 'helpers/helper';
export interface GetAccountResult {
  loggedIn: boolean;
  account?: Account;
  error?: ResponseError;
  isSSOLoggedIn: boolean;
}

export interface UpdateAccount {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDay?: number;
  phone_number?: string;
  work_email?: string;
  role_type?: string;
  company_name?: string;
  update_product_service?: boolean;
  vatNumber?: string;
  ifscCode?: string;
  bankName?: string;
  accountNumber?: string;
  accountHolderName?: string;
  canceledCheque?: string;
}

export interface RegisterAccount extends UpdateAccount {
  email: string;
  password: string;
  // we need to pass the custom field here like workemail,phoneNumber..etc.,
  customerGroupId?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export const getAccount = (): GetAccountResult => {
  const result = useSWR<Account | GetAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions);

  const account = (result.data as GetAccountResult)?.account || (result.data as Account);

  if (account?.accountId && account?.confirmed) {
    let respObj : any = { account, loggedIn: true, isSSOLoggedIn: false };
    if(account?.email && checkIfUserIsSSOVerified(account?.email)) {
      respObj.isSSOLoggedIn = true;
    }
    return respObj;
  };

  return {
    loggedIn: false,
    account: undefined,
    error: result.error,
    isSSOLoggedIn: false,
  };
};

export const login = async (email: string, password: string, remember?: boolean): Promise<Account> => {
  const payload = {
    email,
    password,
  };
  if (remember) window.localStorage.setItem(REMEMBER_ME, '1');
  const res = await fetchApiHub('/action/account/login', { method: 'POST' }, payload);
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart');
  await mutate('/action/wishlist/getWishlist');
  return res;
};

// export const loginWithOTP = async (accountData): Promise<Account> => {
//   const payload = accountData;
export const loginWithOTP = async (accountData, isEmailVerified): Promise<Account> => {
  const payload = {
    ...accountData,
    isEmailVerified: isEmailVerified,
  };
  const res = await fetchApiHub('/action/account/loginWithOTP', { method: 'POST' }, payload);
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart');
  await mutate('/action/wishlist/getWishlist');
  return res;
};

export const logout = async () => {
  window.localStorage.removeItem(REMEMBER_ME);
  window.localStorage.removeItem('userName');
  window.localStorage.removeItem('punchoutUrl');
  window.localStorage.removeItem('customerGroup');
  window.localStorage.removeItem('hookUrl');
  window.localStorage.removeItem('lineItems');
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' });
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart');
  await mutate('/action/wishlist/getWishlist');
  return res;
};

export const register = async (account: RegisterAccount): Promise<Account> => {
  const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account);
  return response;
};

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  };
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
  return res;
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword });
};

export const requestPasswordReset = async (email: string): Promise<Response> => {
  const payload = {
    email,
  };

  const response = await resetFetchApiHub('/action/account/requestReset', { method: 'POST' }, payload);
  return response;
};

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};
