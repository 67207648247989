import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import 'tailwindcss/tailwind.css';
import '../styles/app.css';
import '../styles/themes/default.css';
import '../styles/themes/theme1.css';
import '../styles/themes/theme2.css';
import '../styles/themes/theme3.css';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';
import Toaster from 'components/commercetools-ui/toaster';
import {
  AZURE_CLIENT_ID,
  AZURE_TENANT_ID,
  BEARERTOKEN_BASE_URL,
  BEARERTOKEN_PASSWORD,
  BEARERTOKEN_USERNAME,
  COMMERCETOOLS_BASE_URL,
} from 'helpers/constants/envVariables';
import { appWithTranslation } from 'next-i18next';
import { FrontasticProvider } from 'frontastic';

// Configuratio for Apollo graphql Client
const httpLink = createHttpLink({
  uri: COMMERCETOOLS_BASE_URL + '/graphql',
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = '';
  if (typeof window !== 'undefined') {
    token = localStorage.getItem('token');
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const [tokenLoaded, setTokenLoaded] = useState(false);

  // Load auth token for API calls
  useEffect(() => {
    axios
      .post(
        BEARERTOKEN_BASE_URL,
        {},
        {
          auth: {
            username: BEARERTOKEN_USERNAME,
            password: BEARERTOKEN_PASSWORD,
          },
        },
      )
      .then((response) => {
        if (typeof window !== 'undefined') {
          localStorage.setItem('token', response.data['access_token']);
          setTokenLoaded(true);
        }
      });
  }, []);
  // SSO Public Application for Azure
  const ssoPCA = new PublicClientApplication({
    auth: {
      clientId: AZURE_CLIENT_ID,
      redirectUri: '/login',
      authority: 'https://login.microsoftonline.com/' + AZURE_TENANT_ID,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  });

  // Apollo COnfigurations for graphql

  return (
    <FrontasticProvider>
      {tokenLoaded && (
        <>
          <MsalProvider instance={ssoPCA}>
            <ApolloProvider client={apolloClient}>
              <Component {...pageProps} />
              <Toaster />
            </ApolloProvider>
          </MsalProvider>
        </>
      )}
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
